<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card :loading="loading">
        <v-card-title></v-card-title>
        <v-card-text>
          <v-container v-if="dataLoaded">
            <v-row>
                <v-col cols="12" lg="6" md="6">
                  <v-card class="align-center" elevation="0">
                    <v-card-title class="py-0">
                      <v-alert type="info">
                        Current Data
                      </v-alert>
                    </v-card-title>
                    <v-card-text class="py-0">
                      <div>
                        <p class="text-15 mb-0 font-weight-bold">Employee Name</p>
                        <span class="text-15"
                          v-html="currentRecord.appliedTo"></span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">From Date</p>
                        <span class="text-15">{{ currentRecord.from }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">To Date</p>
                        <span class="text-15">{{ currentRecord.to }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Time in</p>
                        <span class="text-15">{{ currentRecord.timein }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Time out</p>
                        <span class="text-15">{{ currentRecord.timeout }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Day off</p>
                        <span class="text-15">{{ currentRecord.day_off }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" lg="6" md="6">
                  <v-card class="align-center" elevation="0">
                    <v-card-title class="py-0">
                      <v-alert type="success">
                        New Data
                      </v-alert>
                    </v-card-title>
                    <v-card-text class="py-0">
                      <div>
                        <p class="text-15 mb-0 font-weight-bold">Employee Name</p>
                        <span class="text-15"
                          v-html="newRecord.name"></span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">From Date</p>
                        <span class="text-15">{{ newRecord.from }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">To Date</p>
                        <span class="text-15">{{ newRecord.to }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Time in</p>
                        <span class="text-15">{{ newRecord.timein }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Time out</p>
                        <span class="text-15">{{ newRecord.timeout }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Day off</p>
                        <span class="text-15">{{ newRecord.day_off }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Remarks</p>
                        <span class="text-15">{{ newRecord.remarks }}</span>
                        <p class="text-15 mt-2 mb-0 font-weight-bold">Requested By</p>
                        <span class="text-15">{{ newRecord.requested_by }}</span>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12">
            <v-btn
              color="blue darken-1"
              text
              @click="approve(currentRecord.id)"
            >
              Approve
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"
            >
              Close
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      @close="message=''"
    ></snackbar>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import snackbar from '@/components/SnackBar.vue'
import fnx from '@/functions/fn'
import crud from '@/composables/crud'

export default {
  components: {
    snackbar,
  },

  props: {
    show: Boolean,
    data: Object,
  },

  setup(props, { emit }) {
    const loading = ref(false)
    const dataLoaded = ref(false)
    const dialog = ref(false)
    const currentRecord = ref(null)
    const newRecord = ref(null)
    const message = ref('')
    const { update, responseMessage } = crud()

    watch(() => props.show, async val => {
      dialog.value = val
      currentRecord.value = props.data
      newRecord.value = JSON.parse(props.data.changes)
      dataLoaded.value = true
    })

    const approve = async id => {
      await update(`${process.env.VUE_APP_URI}/api/scheduler/schedule/${id}/approve`, loading)
      dialog.value = false
      dataLoaded.value = false
      emit('closeDialog')
      message.value = responseMessage.value
    }

    const closeDialog = () => {
      dialog.value = false
      dataLoaded.value = false
      emit('closeDialog')
    }

    return {
      fnx,
      message,
      loading,
      dialog,
      dataLoaded,
      currentRecord,
      newRecord,
      approve,
      closeDialog,
    }
  },
}
</script>
