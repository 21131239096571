<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        title="Add New"
        v-else
      >
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-autocomplete
          v-if="outletAssignments.length > 1"
          v-model="outlet"
          outlined
          dense
          clearable
          label="Outlet"
          item-value="id"
          item-text="title"
          :loading="outletLoading"
          :items="outlets"
          :class="formErrorFields.outletErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.outletErrorMessage"
          :disabled="formState===null"
          @change="getEmployee"
        ></v-autocomplete>

        <v-autocomplete
          v-model="employee"
          outlined
          dense
          clearable
          multiple
          label="Employee"
          item-value="id"
          item-text="name"
          :loading="employeeLoading"
          :items="employees"
          :class="formErrorFields.employeeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.employeeErrorMessage"
          :disabled="formState===null"
          @change="formError().remove('employee', formErrorFields)"
        ></v-autocomplete>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- From Date -->
            <v-dialog
              ref="dialog"
              v-model="modalFromDate"
              :return-value.sync="fromDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  placeholder="From Date"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.fromDateErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('fromDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fromDate" scrollable>
                <v-btn text color="primary" @click="modalFromDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFromDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- To Date -->
            <v-dialog
              ref="dialog"
              v-model="modalToDate"
              :return-value.sync="toDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  placeholder="To Date"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.toDateErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('toDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="toDate" scrollable>
                <v-btn text color="primary" @click="modalToDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalToDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- In Time -->
            <v-dialog
              ref="dialog"
              v-model="modalTimeIn"
              :return-value.sync="timeInValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeIn"
                  label="Time In"
                  outlined
                  dense
                  placeholder="Time In"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.clock"
                  :class="formErrorFields.timeInErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.timeInErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('timeIn', formErrorFields)"
                ></v-text-field>
              </template>
              <v-time-picker v-model="timeIn" scrollable>
                <v-btn text color="primary" @click="modalTimeIn=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalTimeIn=false">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- Time Out -->
            <v-dialog
              ref="dialog"
              v-model="modalTimeOut"
              :return-value.sync="timeOutValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeOut"
                  label="Time Out"
                  outlined
                  dense
                  placeholder="Time Out"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.clock"
                  :class="formErrorFields.timeOutErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.timeOutErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('timeOut', formErrorFields)"
                ></v-text-field>
              </template>
              <v-time-picker v-model="timeOut" scrollable>
                <v-btn text color="primary" @click="modalTimeOut=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalTimeOut=false">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-row
          v-if="formState==='Edit'"
          class="mt-0 pt-0"
        >
          <v-col cols="12" class="mt-0 pt-0">
            <v-textarea
              v-model="remarks"
              label="Remarks"
              outlined
              rows="3"
              placeholder="Remarks"
              :append-icon="icon.clock"
              :class="formErrorFields.remarksErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.remarksErrorMessage"
              @click="formError().remove('remarks', formErrorFields)"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-checkbox
          class="pt-0 mt-0"
          v-model="dayOff"
          label="Day Off"
          :disabled="formState===null"
        ></v-checkbox>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarOutline, mdiClockOutline, mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'
import fnx from '@/functions/fn'

// import SearchEmployee from '@/components/SearchEmployee.vue'

export default {
  components: {
    snackbar,
  },
  props: ['data'],
  setup(props, { emit, root }) {
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      calendar: mdiCalendarOutline,
      clock: mdiClockOutline,
    }
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const timeInValue = ref(null)
    const timeOutValue = ref(null)
    const employee = ref(null)
    const employees = ref([])
    const employeeLoading = ref(false)
    const outlet = ref(null)
    const outlets = ref([])
    const outletLoading = ref(false)
    const outletAssignments = ref([])
    const dayOff = ref(false)
    const fromDate = ref('')
    const toDate = ref('')
    const timeIn = ref('')
    const timeOut = ref('')
    const remarks = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const modalTimeIn = ref(false)
    const modalTimeOut = ref(false)
    const formState = ref(null)
    const formErrorFields = ref({
      outletErrorMessage: '',
      employeeErrorMessage: '',
      timeInErrorMessage: '',
      timeOutErrorMessage: '',
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
      dayOffErrorMessage: '',
      remarksErrorMessage: '',
    })

    const getEmployee = id => {
      get(`${process.env.VUE_APP_URI}/api/scheduler/employees/${id}/true`, employees, employeeLoading)
    }

    watch(() => props.data, async () => {
      outlet.value = props.data.outlet
      await getEmployee(outlet.value)

      employee.value = props.data.applicable_to_employee !== null ? props.data.applicable_to_employee.split(',') : []
      employee.value = employee.value.map(val => parseInt(val, 10))
      fromDate.value = props.data.from
      toDate.value = props.data.to
      timeIn.value = props.data.timein
      timeOut.value = props.data.timeout
      dayOff.value = props.data.day_off === 'Y'
      remarks.value = props.data.remarks
      formState.value = 'Edit'
    })

    outletAssignments.value = root.$store.state.user.user.outlet_assignment.split(',')
    if (outletAssignments.value.length > 1) {
      get(`${process.env.VUE_APP_URI}/api/scheduler/outlets/${fnx.base64_encode(outletAssignments.value)}`, outlets, outletLoading)
    } else {
      outlet.value = root.$store.state.user.user.outlet_assignment
      get(`${process.env.VUE_APP_URI}/api/scheduler/employees/${outlet.value}/true`, employees, employeeLoading)
    }

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      employee.value = null
      dayOff.value = false
      fromDate.value = null
      toDate.value = null
      timeIn.value = null
      timeOut.value = null
      remarks.value = null
    }

    const submit = async () => {
      const formData = {
        employee: employee.value,
        fromDate: fromDate.value,
        toDate: toDate.value,
        timeIn: timeIn.value,
        timeOut: timeOut.value,
        dayOff: dayOff.value ? 'Y' : 'N',
        remarks: remarks.value,
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/scheduler/schedule`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/scheduler/schedule/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
        reset()
      }
    }

    return {
      getEmployee,
      employee,
      employees,
      employeeLoading,
      outlet,
      outlets,
      outletLoading,
      outletAssignments,
      modalTimeIn,
      timeIn,
      timeInValue,
      modalTimeOut,
      timeOut,
      timeOutValue,
      modalFromDate,
      fromDate,
      fromDateValue,
      modalToDate,
      toDate,
      toDateValue,
      dayOff,
      remarks,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
    }
  },
}
</script>
