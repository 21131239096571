<template>
  <v-row>
    <v-col
      v-if="role==='encoder'"
      cols="12"
      class="mb-6"
      lg="4"
      md="4"
      xl="4"
    >
      <form-view
        :data="fomrData"
        @updateUniqueID="updateUniqueID"></form-view>
    </v-col>
    <v-col
      cols="12"
      :lg="role==='encoder' ? 8 : 12"
      :md="role==='encoder' ? 8 : 12"
      :xl="role==='encoder' ? 8 : 12"
    >
      <list-view
        @passData="passData"
        @viewChanges="viewChanges"
        :uniqueID="uniqueId"></list-view>
    </v-col>

    <view-data
      :data="data"
      :show="dialog"
      @closeDialog="dialog=false"
    ></view-data>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import FormView from './form.vue'
import ListView from './list.vue'
import ViewData from './data.vue'

export default {
  metaInfo: {
    title: 'Overtime Request',
  },

  components: {
    ListView,
    FormView,
    ViewData,
  },

  setup(props, { root }) {
    const fomrData = ref({})
    const uniqueId = ref('')
    const data = ref({})
    const dialog = ref(false)

    const passData = d => {
      fomrData.value = d
    }

    const viewChanges = val => {
      data.value = val
      dialog.value = true
    }

    const updateUniqueID = () => {
      uniqueId.value = Math.random()
    }

    return {
      fomrData,
      passData,
      updateUniqueID,
      viewChanges,
      uniqueId,
      data,
      dialog,
      role: root.$store.state.user.user.role,
    }
  },
}
</script>
